import { ActionIcon, Stack, Tooltip } from "@mantine/core";
import { type ReactNode } from "react";
import { getLogger } from "@expert/logging";
import { useAnalytics } from "../../analytics";
import { MessagingIcon } from "./icons/MessagingIcon";
import { CircleCashIcon } from "./icons/CircleCashIcon";
import { type SideBarTabs, useSideBarStore } from "./store/useSideBarStore";
import styles from "./SideBar.module.css";

const logger = getLogger({
    module: "SideBar",
    supportTeam: "sales",
});

const tabMapping: { name: SideBarTabs; buildIcon: (fillColor: string) => ReactNode }[] = [
    { name: "Assist", buildIcon: (fillColor) => <MessagingIcon fill={fillColor} /> },
    { name: "My stats", buildIcon: (fillColor) => <CircleCashIcon fill={fillColor} /> },
];

export function SideBar(): JSX.Element {
    const [selectedTab, setSelectedTab] = useSideBarStore((state) => [state.selectedTab, state.setSelectedTab]);
    const { dispatcher } = useAnalytics();
    return (
        <Stack align="center" bg="dark.7" w="5rem">
            {tabMapping.map((tab) => {
                const isSelected = selectedTab === tab.name;
                return (
                    <Tooltip
                        bg="var(--mantine-color-dark-9)"
                        key={tab.name}
                        label={tab.name}
                        position="right"
                        zIndex="var(--mantine-priority-highest)"
                    >
                        <ActionIcon
                            size="56px"
                            className={isSelected ? styles.selectedTab : styles.unselectedTab}
                            onClick={() => {
                                setSelectedTab(tab.name);
                                void dispatcher.dispatchBusinessEvent("TabChanged", { selectedTab: tab.name });
                                logger.info({ selectedTab: tab.name }, "TabChanged");
                            }}
                        >
                            {tab.buildIcon(
                                isSelected ? "var(--mantine-color-primary-2)" : "var(--mantine-color-dark-2)",
                            )}
                        </ActionIcon>
                    </Tooltip>
                );
            })}
        </Stack>
    );
}
