export const MAX_SUMMARY_LENGTH = 930;

export const getTimeRemaining = (expirationTimestamp: number | undefined) => {
    return expirationTimestamp ? Math.max(expirationTimestamp - Date.now(), 0) : 0;
};

export function getPartnerTool(partner: string) {
    switch (partner) {
        case "verizon":
            return "ACSS";

        case "att":
            return "OPUS";

        default:
            console.log(`No partner tool found for this partner: ${partner}`);
    }
}
