import { create } from "zustand";
import { persist } from "zustand/middleware";
import type { IntegratedSalesSteps } from "../components/types";

export interface CurrentStepStore {
    integratedStep: IntegratedSalesSteps;
    updateIntegratedStep: (step: IntegratedSalesSteps) => void;
    resetIntegratedStepStore: () => void;
}

export const useIntegratedFlowStore = create<CurrentStepStore>()(
    persist(
        (set) => ({
            integratedStep: "SuggestedOfferStep",
            updateIntegratedStep: (integratedStep: IntegratedSalesSteps) => {
                set(() => ({ integratedStep }));
            },
            resetIntegratedStepStore: () => {
                set(() => ({ integratedStep: "SuggestedOfferStep" }));
            },
        }),
        { name: "integrated-flow-store", skipHydration: true },
    ),
);
