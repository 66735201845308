import { Button, Flex, Group, Text } from "@mantine/core";
import { useAsyncLoading } from "../../shared-utils";
import { withPolymorphicClickAnalytics } from "../../analytics";
import { useCallbacksFormStore } from "../../call-controls/ScheduleCallback/callbacks.store";
import { useScheduleCallback } from "../../call-controls/ScheduleCallback/useScheduleCallback";
import { useSessionSummaryStore } from "../store/useSessionSummaryStore";
import { WrappingCountdownText } from "../../call-controls";
import { callbackActionConfig } from "../../call-controls/ScheduleCallback/utilities";
import styles from "./EditSessionSummaryModal.module.css";

function Countdown({ expirySeconds }: { expirySeconds: number }) {
    return (
        <WrappingCountdownText
            appendUnit={false}
            format="ss"
            ff="monospace"
            fw="var(--mantine-font-weight-normal)"
            mt="0.1rem"
            size="xs"
            totalSeconds={expirySeconds}
        />
    );
}

interface SessionSummaryActionsProps {
    onBackClick: () => void;
    afterSubmitClickDefault: () => void;
    getButtonText: () => string;
    setView: (view: "edit-summary" | "schedule-callback") => void;
    endSession: () => Promise<void>;
    summaryUnavailable: boolean;
    shouldCopy: boolean;
    expirySeconds: number;
    view: "edit-summary" | "schedule-callback";
}

export function SessionSummaryActions({
    onBackClick,
    afterSubmitClickDefault,
    getButtonText,
    setView,
    endSession,
    summaryUnavailable,
    shouldCopy,
    expirySeconds,
    view,
}: SessionSummaryActionsProps) {
    return view === "schedule-callback" ? (
        <SummaryCallbackActions
            onBackClick={onBackClick}
            afterSubmitClickDefault={afterSubmitClickDefault}
            expirySeconds={expirySeconds}
        />
    ) : (
        <SummaryEditActions
            getButtonText={getButtonText}
            setView={setView}
            endSession={endSession}
            summaryUnavailable={summaryUnavailable}
            shouldCopy={shouldCopy}
            expirySeconds={expirySeconds}
        />
    );
}

interface SummaryCallbackActionsProps {
    onBackClick: () => void;
    afterSubmitClickDefault: () => void;
    expirySeconds: number;
}

function SummaryCallbackActions({ onBackClick, afterSubmitClickDefault, expirySeconds }: SummaryCallbackActionsProps) {
    const AnalyticsButton = withPolymorphicClickAnalytics(Button, "Click");

    const { callbackType, selectedCallbackTime, selectedCallbackNowDelay } = useCallbacksFormStore();
    const { scheduleCallback, canScheduleOrUpdateCallback } = useScheduleCallback();

    const [isSubmitting, onSubmit] = useAsyncLoading(async () => {
        await scheduleCallback();
        afterSubmitClickDefault();
    });

    return (
        <Group id="callback-actions" justify="flex-end">
            <AnalyticsButton
                data-testid={callbackActionConfig.back.testId}
                analytics={{
                    actionId: callbackActionConfig.back.actionId,
                }}
                size="sm"
                title="Back"
                onClick={onBackClick}
                color="gray.0"
                variant="subtle"
            >
                <Text fz="sm" td="underline">
                    Back
                </Text>
            </AnalyticsButton>

            <AnalyticsButton
                data-testid={callbackActionConfig.schedule.testId}
                analytics={{
                    actionId: callbackActionConfig.schedule.actionId,
                    eventData: {
                        callbackType,
                        callbackTime: selectedCallbackTime,
                        callbackDelay: selectedCallbackNowDelay,
                    },
                }}
                title="Schedule callback"
                size="sm"
                disabled={!canScheduleOrUpdateCallback || isSubmitting}
                loading={isSubmitting}
                onClick={onSubmit}
                variant="filled"
            >
                Schedule callback (<Countdown expirySeconds={expirySeconds} />)
            </AnalyticsButton>
        </Group>
    );
}

interface SummaryEditActionsProps {
    getButtonText: () => string;
    setView: (view: "edit-summary" | "schedule-callback") => void;
    endSession: () => Promise<void>;
    summaryUnavailable: boolean;
    shouldCopy: boolean;
    expirySeconds: number;
}

function SummaryEditActions({
    getButtonText,
    setView,
    endSession,
    summaryUnavailable,
    expirySeconds,
}: SummaryEditActionsProps) {
    const CallCustomerButton = withPolymorphicClickAnalytics(Button, "Click");
    const SendSummaryButton = withPolymorphicClickAnalytics(Button, "Click");

    const { resolutionStatus } = useSessionSummaryStore();

    function handleScheduleCallbackClick() {
        setView("schedule-callback");
    }

    return (
        <Flex id="modal-actions" justify="flex-end" pt="xl" gap="xs">
            <CallCustomerButton
                analytics={{
                    actionId: "SessionSummaryModal_CallCustomerButton",
                }}
                className={styles.callbackButton}
                size="sm"
                color="white"
                onClick={handleScheduleCallbackClick}
                variant="transparent"
            >
                <Text fw="lighter" fz="sm">
                    Callback
                </Text>
            </CallCustomerButton>
            <SendSummaryButton
                analytics={{
                    actionId: "SessionSummaryModal_SendButton",
                }}
                className={styles.sendSummaryButton}
                aria-label={getButtonText()}
                disabled={summaryUnavailable || resolutionStatus === null}
                size="sm"
                onClick={endSession}
                data-testid="call-controls-end-session"
            >
                {getButtonText()} (<Countdown expirySeconds={expirySeconds} />)
            </SendSummaryButton>
        </Flex>
    );
}
