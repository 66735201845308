import { useAnalytics } from "../../analytics";
import { usePartner } from "../../sdk";
import { getTimeRemaining } from "./utils";

const SESSION_SUMMARY_STATS_EVENT = "SessionSummary_Stats";
const SESSION_SUMMARY_SUBMIT_EVENT = "SessionSummary_Submit";

interface SessionSummaryStatsOptionsPayload {
    agentCallSid: string | undefined;
    autogeneratedSummary?: string;
    update?: string;
    summaryWasSubmittedViaACSS?: boolean;
    summaryWasSubmittedViaPartnerTool?: boolean;
    summaryWasCopiedToClipboard?: boolean;
    timeRanOut?: boolean;
    expirationTimestamp?: number | undefined;
    gaiaSummaryModel?: string;
}

interface SessionSubmitOptionsPayload {
    agentCallSid: string | undefined;
    mdn: string;
    mdnHashed: string;
    sessionSummaryPayload: Record<string, unknown>;
}

export const useSessionSummaryDispatchActions = () => {
    const { dispatcher } = useAnalytics();
    const partner = usePartner();

    const dispatchSessionSubmit = ({
        agentCallSid,
        mdn,
        mdnHashed,
        sessionSummaryPayload,
    }: SessionSubmitOptionsPayload) => {
        void dispatcher
            .withIdentities({ CallSid: agentCallSid, mdn, HashedMdn: mdnHashed })
            .withMeta({ Client: partner, Channel: "voice", SkipMixpanel: true, SkipEDP: false, SkipOpenSearch: true })
            .dispatchBusinessEvent(SESSION_SUMMARY_SUBMIT_EVENT, sessionSummaryPayload);
    };

    const dispatchSessionSummaryStats = ({
        agentCallSid,
        autogeneratedSummary,
        update,
        summaryWasCopiedToClipboard,
        summaryWasSubmittedViaACSS,
        summaryWasSubmittedViaPartnerTool,
        timeRanOut,
        expirationTimestamp,
        gaiaSummaryModel,
    }: SessionSummaryStatsOptionsPayload) => {
        void dispatcher
            .withIdentities({ CallSid: agentCallSid })
            .withMeta({ Client: partner, Channel: "voice", SkipEDP: false })
            .dispatchBusinessEvent(SESSION_SUMMARY_STATS_EVENT, {
                summaryWasAutoGenerated: autogeneratedSummary !== "",
                summaryWasEdited: update !== autogeneratedSummary,
                summaryWasSubmittedViaACSS: summaryWasSubmittedViaACSS ?? false,
                summaryWasSubmittedViaPartnerTool: summaryWasSubmittedViaPartnerTool ?? false,
                summaryWasCopiedToClipboard: summaryWasCopiedToClipboard ?? false,
                summaryLength: update?.length ?? 0,
                timeRanOut: timeRanOut ?? false,
                timeRemainingInMs: getTimeRemaining(expirationTimestamp),
                gaiaSummaryModel,
            });
    };

    return { dispatchSessionSummaryStats, dispatchSessionSubmit };
};
