import { Flex, Group, Text, Textarea } from "@mantine/core";
import { useCallback } from "react";
import { RadioCard, Quotes } from "../../common-ui";
import type { VoiceTask } from "../../sdk";
import { isVoiceTask, useMostRecentTask } from "../../sdk";
import type { SummaryResolution } from "../store/useSessionSummaryStore";
import { useSessionSummaryStore } from "../store/useSessionSummaryStore";
import { useFeatureFlag, validatePhoneNumber } from "../../shared-utils";
import { type Partner } from "../../shared-types";
import { CustomerConfirmationStatusCard } from "./CustomerConfirmationStatusCard";
import classes from "./EditSessionSummaryModal.module.css";
import { MAX_SUMMARY_LENGTH } from "./utils";
import { SendSummaryNotification } from "./SendSummaryNotification";

interface SessionSummaryFormProps {
    partner: Partner;
    loading: boolean;
    shouldShowTextArea: boolean;
    shouldDisableTextArea: boolean;
    canSendSuccessfulSummary: boolean;
}

export function SessionSummaryForm({
    partner,
    loading,
    shouldDisableTextArea,
    shouldShowTextArea,
    canSendSuccessfulSummary,
}: SessionSummaryFormProps): JSX.Element | null {
    /*
        TODO:
        We are attempting to remove the eligibility check notification from the UI.
        If significant time passes without CS complaining that it's missing, go ahead and remove it entirely;
        Otherwise, switch this to true if we need to quickly turn it back on.
    */
    const { data: isCustomerConfirmationStatusCardEnabled } = useFeatureFlag(
        "isCustomerConfirmationStatusCardEnabled",
        { partner },
    );

    const mostRecentTask = useMostRecentTask();

    const { setSessionSummary, setResolved, hideResolutionStatusForm, summary, resolutionStatus } =
        useSessionSummaryStore();

    const shouldHideResolutionForm = hideResolutionStatusForm && resolutionStatus !== null;

    const handleResolution = useCallback(
        (resolution: SummaryResolution) => {
            setResolved(resolution);
        },
        [setResolved],
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setSessionSummary({ value: event.target.value });
        },
        [setSessionSummary],
    );

    return (
        <>
            {isCustomerConfirmationStatusCardEnabled &&
            isVoiceTask(mostRecentTask) &&
            validatePhoneNumber(mostRecentTask.mdn).valid ? (
                <CustomerConfirmationStatusCard
                    asurionCallId={mostRecentTask.asurionCallId}
                    mdn={mostRecentTask.mdn}
                    reservationSid={
                        (
                            mostRecentTask as VoiceTask & {
                                config?: { reservation?: { sid: string } };
                            }
                        ).config?.reservation?.sid
                    }
                />
            ) : null}
            {!shouldShowTextArea && (
                <SendSummaryNotification
                    partner={partner}
                    loading={loading}
                    summarySendSucceeded={canSendSuccessfulSummary}
                />
            )}
            {shouldShowTextArea && (
                <Flex className={classes.modalTextareaContainer} direction="column">
                    <Textarea
                        className="fs-mask"
                        classNames={{
                            input: classes.modalTextarea,
                            section: classes.modalTextareaSection,
                        }}
                        maxLength={MAX_SUMMARY_LENGTH}
                        leftSection={shouldDisableTextArea ? <Quotes width={22} height={17} /> : null}
                        mr="0.125rem"
                        mt="0.25rem"
                        mb="0.3rem"
                        onChange={handleChange}
                        disabled={shouldDisableTextArea}
                        placeholder="Add the issue and resolution steps."
                        rows={5}
                        value={summary}
                        data-testid="session-summary-text-input"
                    />
                </Flex>
            )}
            {!shouldHideResolutionForm && (
                <>
                    <Text size="sm" mt="xl" mb=".5rem">
                        Was the customer’s issue resolved?
                    </Text>
                    <Group>
                        <RadioCard
                            id="resolved-radio-button"
                            analytics={{
                                actionId: "SessionSummaryModal_ProblemSolvedYes",
                            }}
                            color="var(--mantine-color-dark-5)"
                            label="Yes"
                            onClick={() => handleResolution("resolved")}
                            p="0.25rem 0.75rem"
                            selected={resolutionStatus === "resolved"}
                            data-testid="session-summary-problem-solved-yes"
                        />
                        <RadioCard
                            id="not-resolved-radio-button"
                            analytics={{
                                actionId: "SessionSummaryModal_ProblemSolvedNo",
                            }}
                            color="var(--mantine-color-dark-5)"
                            label="No"
                            p="0.25rem 0.75rem"
                            onClick={() => handleResolution("not resolved")}
                            selected={resolutionStatus === "not resolved"}
                            data-testid="session-summary-problem-solved-no"
                        />
                        <RadioCard
                            id="transfer-radio-button"
                            analytics={{
                                actionId: "SessionSummaryModal_ProblemSolvedTransferred",
                            }}
                            p="0.25rem 0.75rem"
                            color="var(--mantine-color-dark-5)"
                            label="Transferred"
                            onClick={() => handleResolution("transferred")}
                            selected={resolutionStatus === "transferred"}
                            data-testid="session-summary-problem-solved-transferred"
                        />
                    </Group>
                </>
            )}
        </>
    );
}
