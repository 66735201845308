import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

export type SideBarTabs = "Assist" | "My stats";

interface SideBarStoreProps {
    selectedTab: SideBarTabs;
    setSelectedTab: (selectedTab: SideBarTabs) => void;
    reset: () => void;
}

const defaultTab = "Assist";

export const useSideBarStore = createWithEqualityFn<SideBarStoreProps>()(
    immer((set) => ({
        selectedTab: defaultTab,
        setSelectedTab: (selectedTab) => {
            set((state) => {
                state.selectedTab = selectedTab;
            });
        },
        reset: () => {
            set((state) => {
                state.selectedTab = defaultTab;
            });
        },
    })),
    shallow,
);
