import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { persist } from "zustand/middleware";
import { type EligibilityStatus, type SaleProgress } from "../types";

export interface EligibleProduct {
    id: string;
    productSku: string;
    status: EligibilityStatus;
    saleProgress: SaleProgress;
    isPrimaryProduct: boolean;
    confirmationCodePattern?: string;
}

interface HomeProductSessionStore {
    homeProduct?: EligibleProduct;
    updateProduct: (product?: EligibleProduct) => void;
    resetSaleSession: () => void;
}

export const useHomeProductSessionStore = createWithEqualityFn<HomeProductSessionStore>()(
    persist(
        immer((set) => ({
            homeProduct: undefined,
            updateProduct: (product) => {
                set((state) => {
                    state.homeProduct = product;
                });
            },
            resetSaleSession: () => {
                set((state) => {
                    state.homeProduct = undefined;
                });
            },
        })),
        { name: "home-product-store", skipHydration: true },
    ),
    shallow,
);
