import { Button, Flex, Text } from "@mantine/core";
import { useState } from "react";
import { withPolymorphicClickAnalytics } from "../../../../analytics";
import { getSessionId, isVoiceTask, useMostRecentTask, usePartner } from "../../../../sdk";
import { createEnrollment, postDeclination } from "../../api";
import type { EligibleProduct } from "../../stores";
import { useHomeProductSessionStore } from "../../stores";
import type { Enrollment } from "../../types";
import { EnrollmentStatus, SaleProgress } from "../../types";
import { useIntegratedFlowStore } from "../../stores/useIntegratedFlowStore";
import classes from "./EnrollSection.module.css";

interface EnrollSectionProps {
    product: EligibleProduct;
    disclosureChecked: boolean;
    isBackButtonEnabled?: boolean;
    isEnrollButtonEnabled?: boolean;
}

export function EnrollSection({
    product,
    disclosureChecked,
    isBackButtonEnabled = true,
    isEnrollButtonEnabled = true,
}: EnrollSectionProps): JSX.Element {
    const [enrolling, setEnrolling] = useState(false);
    const [declining, setDeclining] = useState(false);
    const [updateProduct] = useHomeProductSessionStore((state) => [state.updateProduct]);
    const mostRecentTask = useMostRecentTask();
    const partner = usePartner();
    if (!isVoiceTask(mostRecentTask)) {
        throw new Error("Active task is not voice task");
    }
    const sessionId = getSessionId();
    const enrollmentDetails: Enrollment = {
        callId: sessionId,
        sessionId,
        partner,
        productOfferId: product.id,
    };
    const enroll = async () => {
        try {
            const { enrollmentStatus } = await createEnrollment(enrollmentDetails);
            enrollmentStatus === EnrollmentStatus.Success
                ? updateProduct({ ...product, ...{ saleProgress: SaleProgress.Success } })
                : updateProduct({ ...product, ...{ saleProgress: SaleProgress.Failure } });
            setEnrolling(false);
        } catch (e) {
            setEnrolling(false);
        }
    };
    const decline = async () => {
        try {
            await postDeclination(enrollmentDetails);
            updateProduct({ ...product, ...{ saleProgress: SaleProgress.Declined } });
            setDeclining(false);
        } catch (e) {
            setDeclining(false);
        }
    };
    const AnalyticsButton = withPolymorphicClickAnalytics(Button, "ButtonClick");
    const [integratedStep, updateIntegratedStep] = useIntegratedFlowStore((state) => [
        state.integratedStep,
        state.updateIntegratedStep,
    ]);

    return (
        <Flex className={classes.bottomControlContainer} justify="space-between">
            <Flex align="center" direction="row" gap="8px">
                {isBackButtonEnabled ? (
                    <AnalyticsButton
                        analytics={{
                            actionId: "GuidedFlowBackButton",
                            eventData: {
                                ProductOfferId: enrollmentDetails.productOfferId,
                                integratedStep,
                            },
                        }}
                        onClick={() => {
                            updateIntegratedStep("SuggestedOfferStep");
                        }}
                        disabled={enrolling || declining}
                        color="white"
                        data-testid="Guided-flow-back-button"
                        variant="transparent"
                    >
                        <Text td="underline" size="sm">
                            Back
                        </Text>
                    </AnalyticsButton>
                ) : null}
            </Flex>

            <Flex className={classes.rightAligned} gap="8px">
                <AnalyticsButton
                    analytics={{
                        actionId: "EnrollmentDeclineButton",
                        eventData: {
                            ProductOfferId: enrollmentDetails.productOfferId,
                            nextStep: integratedStep,
                            isGuidedFlow: true,
                        },
                    }}
                    color="white"
                    data-testid="enrollment-decline-button"
                    disabled={enrolling}
                    loading={declining}
                    onClick={() => {
                        setDeclining(true);
                        void decline();
                    }}
                    size="md"
                    variant="subtle"
                >
                    <Text td="underline" size="sm">
                        Decline
                    </Text>
                </AnalyticsButton>
                {isEnrollButtonEnabled ? (
                    <AnalyticsButton
                        analytics={{
                            actionId: "EnrollmentAcceptButton",
                            eventData: {
                                ProductOfferId: enrollmentDetails.productOfferId,
                                nextStep: integratedStep,
                                isGuidedFlow: true,
                            },
                        }}
                        color="asurion.5"
                        data-testid="enrollment-accept-button"
                        disabled={!disclosureChecked || declining}
                        loading={enrolling}
                        onClick={() => {
                            setEnrolling(true);
                            void enroll();
                        }}
                        radius="xl"
                        size="md"
                        variant="filled"
                    >
                        Enroll
                    </AnalyticsButton>
                ) : null}
            </Flex>
        </Flex>
    );
}
