import { Button, Flex, Image, Text } from "@mantine/core";
import { useCustomerAvatar } from "@expert/contentful";
import { useAnalytics } from "../../../analytics";
import { PhoneNumber, ProperName } from "../../../common-ui";
import { useVerifiedCustomerStore } from "../../../customer-info";
import { useCurrentStepStore, useHomeProductSessionStore, useOtherProductSessionStore } from "../../../sales-tools";
import { SaleProgress } from "../../../sales-tools/home-product/types";
import { useMostRecentTask, useSessionId, type VoiceTask } from "../../../sdk";
import { useVerificationFlowStore } from "../customer-verification";
import { useIntegratedFlowStore } from "../../../sales-tools/home-product/stores/useIntegratedFlowStore";
import classes from "./CustomerProfile.module.css";
import { CustomerPlanInfo } from "./CustomerPlanInfo";
import { EditIcon } from "./EditIcon";

export function CustomerProfile(): JSX.Element {
    const [customer] = useVerifiedCustomerStore((state) => [state.customer]);
    const stepHistoryUpdated = useVerificationFlowStore((state) => state.stepHistory);
    const sessionId = useSessionId();
    const customerAvatarUrl = useCustomerAvatar(sessionId);
    const { dispatcher } = useAnalytics();
    const otherProducts = useOtherProductSessionStore((state) => state.products);
    const homeProduct = useHomeProductSessionStore((state) => state.homeProduct);
    const mostRecentTask = useMostRecentTask();

    const hasDispositionedAnyProduct =
        otherProducts.some((product) => product.saleProgress !== SaleProgress.InProgress) ||
        (homeProduct?.saleProgress && homeProduct.saleProgress !== SaleProgress.InProgress);

    const isProfileTransition = stepHistoryUpdated[stepHistoryUpdated.length - 1] === "VerificationComplete";
    return (
        <Flex className={classes.root}>
            <Flex data-testid="profile-top" className={classes.topSection}>
                <Flex
                    data-testid="empty-cell"
                    className={isProfileTransition ? classes.removeEmptyCell : classes.emptyLeftCell}
                />
                {customerAvatarUrl !== undefined && (
                    <Image className={classes.profilePicture} data-testid="customer-avatar" src={customerAvatarUrl} />
                )}
                <Flex
                    data-testid="customer-detail"
                    className={isProfileTransition ? classes.customerDetailsPost : classes.customerDetailsPre}
                >
                    {isProfileTransition ? (
                        <Flex className={classes.temp} direction="column" gap="4px">
                            <Flex className={classes.upperHalf} justify="space-between">
                                <Flex gap="4px" direction="column" className={classes.details}>
                                    <Text className={`${classes.name} fs-mask`} data-testid="customer-name" size="md">
                                        <ProperName name={customer?.fullName} />
                                    </Text>
                                    <Text
                                        w="max-content"
                                        className={`${classes.phoneNumber} fs-mask data-testid="customer-mdn"`}
                                    >
                                        {customer?.mdn ? <PhoneNumber mdn={customer.mdn} /> : null}
                                    </Text>
                                </Flex>
                                {!hasDispositionedAnyProduct ? (
                                    <Button
                                        id="mdn-edit-button"
                                        onClick={() => {
                                            const reservationSid = (
                                                mostRecentTask as VoiceTask & {
                                                    config?: { reservation?: { sid: string } };
                                                }
                                            ).config?.reservation?.sid;
                                            if (reservationSid) {
                                                void dispatcher
                                                    .withIdentity("ReservationSid", reservationSid)
                                                    .dispatchBusinessEvent("EditMDN");
                                            } else {
                                                void dispatcher.dispatchBusinessEvent("EditMDN");
                                            }
                                            useVerificationFlowStore.getState().pushStep("CustomerPhoneInput");
                                            useHomeProductSessionStore.getState().resetSaleSession();
                                            useOtherProductSessionStore.getState().resetSaleSession();
                                            useCurrentStepStore.getState().resetCurrentStepStore();
                                            useIntegratedFlowStore.getState().resetIntegratedStepStore();
                                        }}
                                        leftSection={<EditIcon />}
                                        color="dark.7"
                                        size="sm"
                                        variant="filled"
                                    >
                                        Edit
                                    </Button>
                                ) : null}
                            </Flex>
                            {customer?.purchaseDate ? <CustomerPlanInfo /> : null}
                        </Flex>
                    ) : null}
                </Flex>
            </Flex>
        </Flex>
    );
}
