import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface DisclosureStepStoreProps {
    step: DisclosureSteps | null;
    recordedDisclosureEndTime: number | null;
    updateStep: (step: DisclosureSteps) => void;
    setRecordedDisclosureEndTime: (date: Date) => void;
    resetCurrentStepStore: () => void;
}

export type DisclosureSteps = "DisclosureTypeSelectionStep" | "TextDisclosureStep" | "SmsDisclosureStep";

export const useDisclosureStepStore = create<DisclosureStepStoreProps>()(
    persist(
        (set) => ({
            step: null,
            recordedDisclosureEndTime: null,
            updateStep: (step: DisclosureSteps) => {
                set(() => ({ step }));
            },
            resetCurrentStepStore: () => {
                set(() => ({ step: null, recordedDisclosureEndTime: null }));
            },
            setRecordedDisclosureEndTime: (date) => {
                set(() => ({ recordedDisclosureEndTime: date.getTime() }));
            },
        }),
        {
            skipHydration: true,
            name: "disclosure-step-store",
        },
    ),
);
