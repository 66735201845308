import { getLogger } from "@expert/logging";
import { Flex, Group, Modal, Text } from "@mantine/core";
import { useClipboard, useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useTimer } from "react-timer-hook";
import { useToast } from "../../common-ui";
import { isVoiceTask, useAgentSdk, useAgentStore, usePartner, useSession } from "../../sdk";
import { useSessionSummaryStore } from "../store/useSessionSummaryStore";
import { ScheduleCallbackForm } from "../../call-controls";
import styles from "./EditSessionSummaryModal.module.css";
import { getPartnerTool } from "./utils";
import { SessionSummaryForm } from "./SessionSummaryForm";
import { SessionSummaryActions } from "./SessionSummaryActions";
import { useSummary } from "./useSummary";

const logger = getLogger({ module: "sessionSummary" });

export function SessionSummary(): JSX.Element | null {
    const clipboard = useClipboard({ timeout: 500 });
    const agentSdk = useAgentSdk();
    const partner = usePartner();
    const toast = useToast();

    const [setCanAppend] = useSessionSummaryStore((state) => [state.setCanAppend]);

    const [view, setView] = useState<"edit-summary" | "schedule-callback">("edit-summary");
    const [summaryLoaded, setSummaryLoaded] = useState(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const [opened, { open: openModal, close: closeModal }] = useDisclosure(false);

    const { activity, pendingActivity } = useAgentStore(useShallow((state) => state));
    const activeSession = useSession();
    const activeTask = activeSession.currentTask;
    if (activeTask && !isVoiceTask(activeTask)) {
        throw new Error("Unsupported task channel. SessionSummary should not be rendered without a voice task");
    }

    const agentCallSid =
        activeTask?.agentCallId ??
        activeSession.tasks.filter(isVoiceTask).findLast((task) => task.agentCallId !== undefined)?.agentCallId;

    const {
        isLoadingSummaryFeature,
        summaryFailed,
        validAccountNumber,
        requiresManualCopy,
        summary,
        setSessionSummaryFromGaia,
        onSessionSummaryCompleted,
        onDismiss,
    } = useSummary();

    const shouldAutoGenerateSummary = activeTask?.locale !== "es-mx";
    const shouldSendSummary = validAccountNumber && !requiresManualCopy;
    const needsCopy = !shouldSendSummary && !isCopied;
    const sessionSummaryLoading = (!summaryLoaded || isLoadingSummaryFeature) && shouldAutoGenerateSummary;
    const canSendSuccessfulSummary = !summaryFailed && shouldSendSummary;
    const shouldShowTextArea = !sessionSummaryLoading && !isCopied && !canSendSuccessfulSummary;
    const shouldDisableTextArea = !summaryFailed && needsCopy;

    // A wrapping task or a newly wrapping agent means we are entering session summary
    const inWrappingState = activeTask?.status === "wrapping" || activity === "Wrapping";
    const taskIsActive = activeTask?.status === "assigned";
    const hasImmediateCallBack = activeSession.callbackState?.callbackType === "CallbackNow";

    async function endSession(reason: "AgentTerminatedWrapping" | "WrappingTimeExpired") {
        logger.trace({ reason, partner }, "Ending session wrapup");
        await onSessionSummaryCompleted(closeModal, summary, !shouldSendSummary, agentCallSid);
        await agentSdk.endWrapup(reason, partner);
    }

    async function handleWrappingExpired() {
        if (needsCopy) copySummary();
        await endSession("WrappingTimeExpired");
    }

    const copySummary = () => {
        try {
            clipboard.copy(summary);
            setIsCopied(true);
            toast.success("Session summary copied to clipboard");
        } catch (err) {
            logger.error({ err }, "Error copying session summary");
        }
    };

    async function handleCopyOrEndSessionClick() {
        needsCopy ? copySummary() : await endSession("AgentTerminatedWrapping");
    }

    /** Prevent mantine's attempt to close the modal when the outside overlay is clicked */
    const interceptClose = () => null;
    const callbackScheduleHandler = () => {
        onDismiss(closeModal, agentCallSid, { checkSummary: false });
    };

    function getSummaryHeader() {
        if (requiresManualCopy) {
            if (summaryFailed) {
                return "Oh no—we couldn’t generate a summary";
            }
            return `Add notes to ${getPartnerTool(partner)}`;
        }
        if (!validAccountNumber) {
            return `Autosend failed—add notes to ${getPartnerTool(partner)}`;
        } else if (summaryFailed) {
            return "Oh no—we couldn’t generate a summary";
        }
        return "Wrap up";
    }

    function getSummaryButtonText() {
        if (needsCopy) {
            return "Copy notes";
        } else if (pendingActivity === "Offline") {
            return "End session and log off";
        } else if (summaryFailed && shouldSendSummary) {
            return "Save and end session";
        }
        return "End session";
    }

    const { totalSeconds } = useTimer({
        expiryTimestamp: new Date(activeSession.wrappingState?.expirationTimestamp ?? Date.now()),
        autoStart: true,
        onExpire: () => void handleWrappingExpired(),
    });

    useEffect(() => setView("edit-summary"), [opened]);

    useEffect(() => {
        if (!shouldAutoGenerateSummary) {
            setSummaryLoaded(true);
            return;
        }

        if (opened && inWrappingState && !isLoadingSummaryFeature) {
            void setSessionSummaryFromGaia(setSummaryLoaded, agentCallSid);
        }
    }, [
        setSessionSummaryFromGaia,
        isLoadingSummaryFeature,
        inWrappingState,
        shouldAutoGenerateSummary,
        opened,
        agentCallSid,
    ]);

    useEffect(() => {
        if (hasImmediateCallBack) return;

        if (inWrappingState) {
            openModal();
        } else if (taskIsActive) {
            setCanAppend(true);
        }
    }, [hasImmediateCallBack, inWrappingState, taskIsActive, setCanAppend, openModal]);

    return (
        <Group className={styles.modalContainer}>
            <Modal
                centered
                classNames={{
                    inner: styles.modalInner,
                    overlay: styles.modalOverlay,
                    content: styles.modalContent,
                }}
                onClose={interceptClose}
                opened={opened}
                overlayProps={{
                    backgroundOpacity: 0.1,
                }}
                size="32rem"
                withCloseButton={false}
            >
                <Flex id="modal-heading" align="center" justify="space-between" mb="xl">
                    <Text className={styles.modalTitle} fw="var(--mantine-font-weight-normal)" fz="md">
                        {view === "schedule-callback" ? "Schedule Callback" : getSummaryHeader()}
                    </Text>
                </Flex>
                {view === "schedule-callback" ? (
                    <ScheduleCallbackForm shouldShowActions={false} />
                ) : (
                    <SessionSummaryForm
                        partner={partner}
                        loading={sessionSummaryLoading}
                        shouldDisableTextArea={shouldDisableTextArea}
                        shouldShowTextArea={shouldShowTextArea}
                        canSendSuccessfulSummary={canSendSuccessfulSummary}
                    />
                )}
                <SessionSummaryActions
                    onBackClick={() => setView("edit-summary")}
                    afterSubmitClickDefault={callbackScheduleHandler}
                    getButtonText={getSummaryButtonText}
                    setView={setView}
                    endSession={handleCopyOrEndSessionClick}
                    summaryUnavailable={sessionSummaryLoading || !summary}
                    shouldCopy={needsCopy}
                    expirySeconds={totalSeconds}
                    view={view}
                />
            </Modal>
        </Group>
    );
}
