import { immer } from "zustand/middleware/immer";
import { createWithEqualityFn } from "zustand/traditional";
import { persist } from "zustand/middleware";
import type { Customer, DeviceReplacement } from "../api";

export interface CustomerState {
    customer?: Customer;
    deviceReplacement?: DeviceReplacement;
    setCustomer: (customer?: Partial<Customer & { mdn: string }>, deviceReplacement?: DeviceReplacement) => void;
    resetCustomer: () => void;
    reset: () => void;
}

export const useVerifiedCustomerStore = createWithEqualityFn<CustomerState>()(
    persist(
        immer((set) => ({
            customer: undefined,
            deviceReplacement: undefined,
            setCustomer: (customer?: Partial<Customer & { mdn: string }>, deviceReplacement?: DeviceReplacement) => {
                set(() => ({ customer }));
                set(() => ({ deviceReplacement }));
            },
            resetCustomer: () => {
                set(() => ({ deviceReplacement: undefined }));
                set(() => ({ customer: undefined }));
            },
            reset: () => {
                set(() => ({ deviceReplacement: undefined }));
                set(() => ({ customer: undefined }));
            },
        })),
        { name: "verified-customer-store", skipHydration: true },
    ),
);
